<template>
  <div class="images">
    <p class="text-h4">3D artwork</p>
    <v-card flat class="d-flex flex-row flex-wrap mb-6">
      <v-card 
        class="mx-5 my-5 align-self-start" 
        max-width="374" 
        v-for="(item, index) in images" :key="index"
        @click="show_image(item)"
      >
        <v-img :src="append_str(item)">
          <template v-slot:placeholder>
            <v-row
              class="fill-height ma-0"
              align="center"
              justify="center"
            >
              <v-progress-circular
                indeterminate
                color="grey lighten-5"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </v-card>
    </v-card>
    <p class="text-h4">Anaglyph photgraphy and 3D renderings</p>
    <v-card flat class="d-flex flex-row flex-wrap mb-6">
      <v-card 
        class="mx-5 my-5 align-self-start" 
        max-width="374" 
        v-for="(item, index) in ana_images" :key="index"
        @click="show_image(item)"
      >
        <v-img :src="append_str(item)"></v-img>
      </v-card>
    </v-card>
    <v-dialog v-model="dialog" :width="display_img_width" :height="display_img_height">
      <v-card :height="card_height" color="rgb(255,0,0,0)">
        <v-img
          contain
          :aspect-ratio="display_img_aspect_ratio"
          :height="display_img_height"
          :src="display_image">
        </v-img>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
    name: 'Images',
    components: {},
    data() {
      return {
        images: ['mobile01.jpg', 'mobile02.jpg', 'mobile03.jpg', 'mobile04.jpg', 'mobile05.jpg', 'badbot.png',  'bike01.jpg', 'bike02.jpg', 'bike03.jpg', 'bike04.jpg', 'bike05.jpg',  'stop.jpg', 'viper1.jpg',  'z4Render.jpg', 'z4wheel.jpg', 'z4White.jpg'],
        ana_images: ['amphi01.png', 'amphi02.png', 'arc2.png', 'bike.png', 'bike1.png', 'bike2.png', 'd2.png', 'final 2.png', 'final.png', 'tent.png', 'viperana.png', 'marlin.jpg', 'marlin2.jpg', 'mobe.png', 'moe.png', 'moreSkyscrapers.jpg', 'plane.jpg', 'bridge.jpg', 'room3d.png', 'skyscrapers.jpg', 'statue.jpg', ],
        display_image:'',
        display_img_width:0,
        display_img_height:0,
        display_img_aspect_ratio:'',
        card_height:0,
        dialog:false
      }
    },
    methods:{
      append_str(data){
        return require('@/assets/images/'+data)
      },
      show_image(image){
        this.display_image = this.append_str(image);
        // console.log(image)        

        let img = new Image();
        img.src = this.display_image;
        img.onload = () => {
          this.display_img_width = img.width.toString();
          this.display_img_height = img.height.toString();
          this.display_img_aspect_ratio = img.width/img.height;
          this.card_height = img.height
          console.log(`the image dimensions are ${img.width}x${img.height}`);
          this.dialog=true;
        }
      }
    }
}
</script>
